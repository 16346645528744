p-table {
  .p-datatable {
    .p-datatable-wrapper {
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
    }

    .p-datatable-thead {
      & > tr {
        & > th {
          text-align: left;
          padding: 1rem 1rem;
          //display: flex;
          align-items: center;
          border: 1px solid #e4e4e4;
          font-size: 14px;
          color: $primary-alternative-dark;
          border-width: 0 0 0 0;
          font-weight: 400;
          background: #F9FAFC;
          transition: none;
          position: relative;

          &:after {
            content: '';
            width: 2px;
            height: 14px;
            background-color: $action-disabled;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .p-datatable-tbody {
      position: relative;

      & > tr {
        cursor: pointer;

        &.p-highlight {
          background: transparent;
          color: transparent;

          td {
            background: rgba($primary-main, 0.12);
            color: $primary-main;
          }
        }

        & > td {
          vertical-align: middle;
          padding: 0.8rem 1rem;
          align-items: center;
          color: $primary-alternative-dark;
          font-size: 12px;
          overflow-wrap: anywhere;
          overflow: hidden;
          position: relative;

          & > em {
            margin: auto;
          }
          & > div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &.not-hoverable {
          &:hover {
            td {
              background-color: transparent;
            }
          }
        }

        &:hover {
          td {
            background: rgba($primary-main, 0.06);
          }
        }
      }

    }

    .p-paginator-bottom {
      background: #eceff4;
      border: none;
      font-size: 12px;
    }
  }
}

.p-datatable {
  &.p-datatable-hoverable-rows {
    .p-datatable-tbody {
      & > tr {
        &:not(.p-highlight) {
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}
