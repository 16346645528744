.p-menu {
  .p-menuitem-link {
    .p-menuitem-icon {
      width: 15px !important;
      height: 15px !important;
    }

    .p-menuitem-text {
      color: $grayscale;
    }
  }
}
