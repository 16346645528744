.pointer {
  cursor: pointer;
}


.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0b7cc3;
  text-transform: uppercase;
}

.personalize-icon {
  border: 1px solid $neutral-3;
  border-radius: 4px;
  padding: 6px 6px;
  cursor: pointer;
}

.lp-dialog-cancel {
  background-color: $secondary-main;

  &:hover {
    background-color: rgba($secondary-alternative-light, 0.96) !important;
  }

  &:focus {
    background: rgba(156, 159, 164, 0.8) !important;
  }
}

.lp-dialog-delete {
  background-color: $error-main !important;

  &:hover {
    background-color: rgba($error-main, 0.8) !important;

  }
}

.lp-dialog-save {
  background-color: $primary-main !important;

  &:hover {
    background-color: rgba($primary-main, 0.8) !important;

  }
}

.icon-button {
  cursor: pointer;
  height: 24px;
  min-width: 24px;

}

.export-excel {
  height: 24px;
  min-width: 24px;
}

//Style hide border when in readonly mode
.readonly-view {
  .readonly-label {
    font-size: 12px;
    color: $text-primary;
  }

  .readonly-content {
    color: $primary-alternative-dark;
    padding-left: 4px;
    padding-top: 4px;
    font-weight: 500;
    overflow-wrap: anywhere;

    &:hover {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

//.readonly {
//  input {
//    color: $primary-alternative-dark;
//    cursor: pointer;
//
//    &.p-inputtext {
//      padding-left: 1.25rem;
//      font-weight: 500;
//
//      &:enabled {
//        &:focus {
//          border: none;
//          box-shadow: none;
//
//          & ~ label {
//            color: $text-primary;
//          }
//        }
//      }
//    }
//
//    border: none;
//  }
//
//  div {
//    &.p-dropdown {
//      border: none;
//      padding-left: 0.4rem;
//
//      .p-dropdown-label {
//        color: $primary-alternative-dark;
//        font-weight: 500;
//      }
//
//      .p-dropdown-trigger {
//        display: none;
//      }
//    }
//
//    &.p-multiselect {
//      border: none;
//
//      .p-multiselect-trigger {
//        display: none;
//      }
//    }
//  }
//
//  &.p-inputwrapper-focus {
//    & ~ label {
//      color: $text-primary;
//    }
//  }
//
//  .p-checkbox-label {
//    font-weight: 500;
//  }
//
//  .p-checkbox {
//    &:not(.p-checkbox-disabled) {
//      &:hover {
//        box-shadow: none;
//      }
//    }
//
//    //.p-checkbox-box {
//    //  opacity: 0.38;
//    //}
//  }
//
//  .p-multiselect {
//    &.p-multiselect-chip {
//      .p-multiselect-token {
//        .p-multiselect-token-icon {
//          display: none;
//        }
//      }
//    }
//  }
//
//}

textarea {
  resize: none;
  //  &.readonly {
  //    cursor: pointer;
  //    border: none;
  //    color: $primary-alternative-dark;
  //    padding-left: 1.25rem;
  //  }
}

//input {
//  &.readonly {
//    cursor: pointer;
//
//    &.p-inputtext {
//      padding-left: 1.25rem;
//      font-weight: 500;
//      color: $primary-alternative-dark;
//      border: none;
//
//      &:enabled {
//        &:focus {
//          border: none;
//          box-shadow: none;
//
//          & ~ label {
//            color: $text-primary;
//          }
//        }
//      }
//    }
//  }
//}


.upload-wrapper {
  display: flex;
  gap: 40px;

  p {
    margin: 0;
  }

  .upload-icon {
    width: 130px;
    height: 130px;
    padding: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px dashed $text-secondary;
    cursor: pointer;
  }

  .upload-text {
    .upload-text-title {
      font-size: 1rem;
      font-weight: 400;
      color: $primary-alternative-dark;
    }

    .upload-text-desc {
      font-size: 1rem;
      font-weight: 500;
      color: $text-secondary;
    }
  }
}

.button-custom {
  display: flex;
  align-items: center;
  gap: 10px;
}

//Display attachment files
.downloadable-files {
  background: $primary-main;
  color: $white;
  padding: 4px 10px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
}

.files-count {
  position: absolute;
  border-radius: 50%;
  background: #FF4C51;
  color: #FFF;
  padding: 4px;
  height: 14px;
  width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -4px;
  right: -4px;
}

.blue-border {
  border-color: $primary-main;
}

.uploaded-file {
  cursor: pointer;
  background: $primary-main;
  color: $white;
  padding: 4px 10px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.active-status {
  color: $success-main !important;
}

.pending-status {
  color: $warning-main;
}

.deactive-status {
  color: $error-main !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.edit-btn {
  span {
    &.p-button-icon {
      height: 16px;
    }
  }
}

.add-btn {
  background-color: $primary-main;
  border-radius: 6px;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .lp-plus {
    height: 18px;
    width: 18px;
  }
}

.small-add-btn {
  background-color: $primary-main;
  border-radius: 6px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table-empty-img {
  position: sticky;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  //
  padding-top: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;

  img {
    width: auto;
    max-width: 100px;
    margin-bottom: 18px;
    height: auto;
  }

  span {
    color: $primary-alternative-dark;
  }
}

.chip-view--container {
  display: flex;
  flex-wrap: wrap;

  .chip-view--item {
    padding: 7px 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    background: $primary-outlined-hover-background;
    color: $primary-alternative-dark;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.p-input-icon-right {
  display: block;

  & > em {
    position: absolute;
    transform: translateY(-50%);
    right: 20px;
    top: 50%;
  }
}

.error-msg {
  position: relative;
  z-index: 1;
  background: $white;
}

.button-stick-bottom {
  min-height: 58px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.detail-button {
  color: $primary-alternative-dark;
  background: $primary-outlined-hover-background;
  padding: 8px 12px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: rgba($primary-outlined-hover-background, 0.05);
  }
}
