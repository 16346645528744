.p-breadcrumb {
  .p-breadcrumb-chevron:before {
    content: '/';
  }

  ul {
    li {
      .p-menuitem-link {
        .p-menuitem-text {
          color: $text-secondary;
        }
      }
    }
  }
}
