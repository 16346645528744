// p-tabView {
//   height: 100%;
// }

.p-tabview {
  &.not-default-tabview {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0.5rem;

    .p-tabview-nav-container { 
      width: 20%;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      background: #ffffff;
      padding: 18px;
      //box-shadow: 0px 2px 10px rgba(58, 53, 65, 0.1);

      .p-tabview-nav-content {

        .p-tabview-nav {
          border: none;
          flex-direction: column;

          li {
            &.p-highlight {
              .p-tabview-nav-link {
                color: $primary-main;
              }
            }

            .p-tabview-nav-link {
              border-bottom: 1px solid rgba(58, 53, 65, 0.08);
              color: $text-primary;
              font-weight: 400;

              &:focus {
                background-color: $primary-outlined-hover-background;
              }
            }
          }

          .p-tabview-ink-bar {
            display: none;
          }
        }
      }

    }

    .p-tabview-panels {
      margin-left: 1.15rem;
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      background: transparent;
      padding: 0;
      //box-shadow: 0 2px 10px #3a35411a;
      .p-tabview-panel {
        height: 100%;

        .main-content {
          height: 100%;
        }
      }
    }

    &.unset-tabview-height{
      height: unset !important;
      .p-tabview-panels{
        overflow: hidden;
      }
    }
  }

  &.default-tabview {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;

    .p-tabview-nav-container {
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      //height: 100%;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      background: #ffffff;
      padding: 0;
      box-shadow: none;

      .p-tabview-nav-content {

        .p-tabview-nav {
          flex-direction: row;
          border: 1px solid #dee2e6;
          border-width: 0 0 1px 0;

          li {
            &.p-highlight {
              .p-tabview-nav-link {
                color: $primary-main;
                border-color: $primary-main
              }
            }

            .p-tabview-nav-link {
              border-bottom: 1px solid rgba(58, 53, 65, 0.08);
              color: $text-primary;

              &:focus {
                background-color: $primary-outlined-hover-background;
              }
            }
          }

          .p-tabview-ink-bar {
            display: none;
          }
        }
      }

    }

    .p-tabview-panels {
      margin-left: 1.15rem;
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      background: transparent;
      padding: 0;
      box-shadow: none;
      .p-tabview-panel {
        height: 100%;
        .main-content {
          height: 100%;
        }
      }
    }
  }
}
