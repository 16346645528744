@import "variable";

.p-toast.p-toast-top-right,
.p-toast.p-toast-top-left,
.p-toast.p-toast-top-center {
  top: 70px;
}

.p-toast {


  .p-toast-message {
    &.p-toast-message-success {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #4CAF50;
      color: $success-alternative-dark;

      .p-toast-icon-close {
        color: $text-secondary;
      }
    }

    &.p-toast-message-error {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #F44336;
      color: $error-alternative-dark;

      .p-toast-icon-close {
        color: $text-secondary;
      }
    }

    .p-toast-message-content {
      padding: 1rem;
      align-items: center;
      border-left: 10px solid;

      .p-toast-message-text {
        margin-left: 12px;
      }

      .p-toast-detail {
        margin: 0;
      }

      .p-toast-message-icon {
        font-size: 1.25rem;
      }
    }
  }
}
