ul, ol {
  margin: 0;
  list-style: none;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
}

a {
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  margin: 0;
}
