.lp-icon {
  display: block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
}

.lp-mail {
  @extend .lp-icon;
  background-image: url('../../icons/mail.svg') !important;
}

.lp-right-chevron {
  @extend .lp-icon;
  background-image: url('../../icons/right-chevron.svg') !important;
  transform: rotate(-90deg);
}

.lp-down-chevron {
  @extend .lp-icon;
  background-image: url('../../icons/down-chevron.svg') !important;
}

.lp-organization {
  @extend .lp-icon;
  background-image: url('../../icons/organization.svg') !important;
}

.lp-onboarding {
  @extend .lp-icon;
  background-image: url('../../icons/onboarding.svg') !important;
}

.lp-employees {
  @extend .lp-icon;
  background-image: url('../../icons/employees.svg') !important;
}

.lp-candidate {
  @extend .lp-icon;
  background-image: url('../../icons/candidate.svg') !important;
}

.lp-review {
  @extend .lp-icon;
  background-image: url('../../icons/review.svg') !important;
}

.lp-resign {
  @extend .lp-icon;
  background-image: url('../../icons/resign.svg') !important;
}

.lp-personal-information {
  @extend .lp-icon;
  background-image: url('../../icons/personal-information.svg') !important;
}

.lp-expand {
  @extend .lp-icon;
  background-image: url('../../icons/expand.svg') !important;
}

.lp-star {
  @extend .lp-icon;
  background-image: url('../../icons/star.svg') !important;
}

.lp-excel {
  @extend .lp-icon;
  background-image: url('../../icons/excel.svg') !important;
}

.lp-detail {
  @extend .lp-icon;
  background-image: url('../../icons/detail.svg') !important;
}

.lp-action {
  @extend .lp-icon;
  background-image: url('../../icons/action.svg') !important;
}

.lp-plus {
  @extend .lp-icon;
  height: 16px;
  width: 16px;
  background-image: url('../../icons/plus.svg') !important;
}

.lp-plus-circle {
  @extend .lp-icon;
  background-image: url('../../icons/circle-plus.svg') !important;
}

.lp-rubbish-bin {
  @extend .lp-icon;
  background-image: url('../../icons/rubbish-bin.svg') !important;
}


.lp-pencil {
  @extend .lp-icon;
  background-image: url('../../icons/pencil.svg') !important;
}

.lp-blue-pencil {
  @extend .lp-icon;
  background-image: url('../../icons/blue-pencil.svg') !important;
}

.lp-upload {
  @extend .lp-icon;
  background-image: url('../../icons/upload.svg') !important;
}

.lp-upload-cloud {
  @extend .lp-icon;
  width: 13.39px;
  height: 11.67px;
  background-image: url('../../icons/upload-cloud.svg') !important;
}

.lp-multiple-files {
  @extend .lp-icon;
  background-image: url('../../icons/multiple-files.svg') !important;
}


.lp-reload {
  @extend .lp-icon;
  height: 22.75px;
  width: 22.75px;
  background-image: url('../../icons/reload.svg') !important;
}

.lp-close {
  @extend .lp-icon;
  background-image: url('../../icons/close.svg') !important;
}


.lp-tick {
  @extend .lp-icon;
  background-image: url('../../icons/tick.svg') !important;
}

.lp-white-tick {
  @extend .lp-icon;
  height: 16px;
  width: 16px;
  background-image: url('../../icons/white-tick.svg') !important;
}

.lp-sand-clock {
  @extend .lp-icon;
  height: 16px;
  width: 16px;
  background-image: url('../../icons/sand-clock.svg') !important;
}

.lp-personalized {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/personalized.svg') !important;
}


.lp-personalized-blue {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/personalized-blue.svg') !important;
}

.lp-admin-menu {
  @extend .lp-icon;
  background-image: url('../../icons/admin-menu.svg') !important;
}

.lp-magnify {
  @extend .lp-icon;
  background-image: url('../../icons/magnify.svg') !important;
}

.lp-magnify-black {
  @extend .lp-icon;
  height: 15px;
  width: 15px;
  background-image: url('../../icons/magnify-black.svg') !important;
}


.lp-arrow-left {
  @extend .lp-icon;
  background-image: url('../../icons/arrow-left.svg') !important;
}


.lp-download {
  @extend .lp-icon;
  background-image: url('../../icons/download.svg') !important;
}


.lp-eye {
  @extend .lp-icon;
  background-image: url('../../icons/eye.svg') !important;
}


.lp-assign {
  @extend .lp-icon;
  background-image: url('../../icons/assign.svg') !important;
}

.lp-assign-user {
  @extend .lp-icon;
  background-image: url('../../icons/assign-user.svg') !important;
}

.lp-birthday-cake {
  @extend .lp-icon;
  background-image: url('../../icons/happy-birthday.svg') !important;
}

.lp-bell {
  @extend .lp-icon;
  background-image: url('../../icons/bell.svg') !important;
}

.lp-birthday-cake-nontext {
  @extend .lp-icon;
  background-image: url('../../icons/birthday-cake-without-lptech.svg') !important;
}

.lp-mini-bdcake {
  @extend .lp-icon;
  background-image: url('../../icons/mini-birthday-cake.svg') !important;
}

.lp-briefcase {
  @extend .lp-icon;
  background-image: url('../../icons/briefcase.svg') !important;
}

.lp-gender-male {
  @extend .lp-icon;
  background-image: url('../../icons/gender-male.svg') !important;
}


.lp-gender-female {
  @extend .lp-icon;
  background-image: url('../../icons/gender-female.svg') !important;
}

.lp-gender-other {
  @extend .lp-icon;
  background-image: url('../../icons/gender-other.svg') !important;
}

.lp-employee-info {
  @extend .lp-icon;
  background-image: url('../../icons/employee-info.svg') !important;
}

.lp-vector {
  @extend .lp-icon;
  background-image: url('../../icons/vector.svg') !important;
}

.lp-date {
  @extend .lp-icon;
  background-image: url('../../icons/date.svg') !important;
}

.lp-address {
  @extend .lp-icon;
  background-image: url('../../icons/address.svg') !important;
}

.lp-email {
  @extend .lp-icon;
  background-image: url('../../icons/email.svg') !important;
}

.lp-phone {
  @extend .lp-icon;
  background-image: url('../../icons/phone.svg') !important;
}

.lp-facebook {
  @extend .lp-icon;
  background-image: url('../../icons/facebook.svg') !important;
}

.lp-linkedin {
  @extend .lp-icon;
  background-image: url('../../icons/linkedin.svg') !important;
}

.lp-add-row {
  @extend .lp-icon;
  background-image: url('../../icons/add-row.svg') !important;
}

.lp-delete-row {
  @extend .lp-icon;
  background-image: url('../../icons/delete-row.svg') !important;
}

.lp-dashboard {
  @extend .lp-icon;
  background-image: url('../../icons/dashboard.svg') !important;
}

.lp-down-blue-chevron {
  @extend .lp-icon;
  width: 14px;
  background-image: url('../../icons/down-blue-chevron.svg') !important;
}

.lp-right-black-chevron {
  @extend .lp-icon;
  width: 14px;
  background-image: url('../../icons/right-black-chevron.svg') !important;
}

.lp-tiktok {
  @extend .lp-icon;
  background-image: url('../../icons/tiktok.svg') !important;
}

.lp-behance {
  @extend .lp-icon;
  background-image: url('../../icons/behance.svg') !important;
}

.lp-figma {
  @extend .lp-icon;
  background-image: url('../../icons/figma.svg') !important;
}

.lp-cv-approval {
  @extend .lp-icon;
  background-image: url('../../icons/cv-approval.svg') !important;
}

.lp-interview {
  @extend .lp-icon;
  background-image: url('../../icons/interview.svg') !important;
}

.lp-review-candidate {
  @extend .lp-icon;
  background-image: url('../../icons/review-candidate.svg') !important;
}

.lp-todo-item-done {
  @extend .lp-icon;
  background-image: url('../../icons/todo-item-done.svg') !important;
}

.lp-todo-item-pending {
  @extend .lp-icon;
  background-image: url('../../icons/todo-item-pending.svg') !important;
}

.lp-salary-consulting {
  @extend .lp-icon;
  background-image: url('../../icons/salary-consulting.svg') !important;
}

.lp-filter {
  @extend .lp-icon;
  background-image: url('../../icons/filter.svg') !important;
}

.lp-cv-review {
  @extend .lp-icon;
  width: 24px;
  height: 24.01px;
  background-image: url('../../icons/progress-cv-review.svg') !important;
}

.lp-candidate-contact {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/progress-candidate-contact.svg') !important;
}

.lp-progress-interview {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/progress-interview.svg') !important;
}

.lp-progress-salary {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/progress-salary.svg') !important;
}

.lp-hr-handling {
  @extend .lp-icon;
  width: 24px;
  height: 18px;
  background-image: url('../../icons/progress-hr-handling.svg') !important;
}

.lp-offer-approved {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/progress-offer.svg') !important;
}

.lp-cancel-progress {
  @extend .lp-icon;
  width: 24px;
  height: 24px;
  background-image: url('../../icons/progress-cancel.svg') !important;
}

.lp-instagram {
  @extend .lp-icon;
  background-image: url('../../icons/instagram.svg') !important;
}

.lp-zalo {
  @extend .lp-icon;
  background-image: url('../../icons/zalo.svg') !important;
}

.lp-download-file {
  @extend .lp-icon;
  background-image: url('../../icons/download-file.svg') !important;
}

.lp-download-document {
  @extend .lp-icon;
  background-image: url('../../icons/download-document.svg') !important;
}

.lp-detail-file {
  @extend .lp-icon;
  background-image: url('../../icons/detail-file.svg') !important;
}

.lp-skip {
  @extend .lp-icon;
  background-image: url('../../icons/skip.svg') !important;
}

.lp-zoom-in {
  @extend .lp-icon;
  background-image: url('../../icons/zoom-in.svg') !important;
}

.lp-zoom-out {
  @extend .lp-icon;
  background-image: url('../../icons/zoom-out.svg') !important;
}

.lp-project {
  @extend .lp-icon;
  background-image: url('../../icons/project.svg') !important;
}

.lp-rate {
  @extend .lp-icon;
  background-image: url('../../icons/rate.svg') !important;
}

.lp-on-leave {
  @extend .lp-icon;
  background-image: url('../../icons/on-leave.svg') !important;
}

.lp-list {
  @extend .lp-icon;
  background-image: url('../../icons/list.svg') !important;
}

.lp-list-active {
  @extend .lp-icon;
  background-image: url('../../icons/list-active.svg') !important;
}

.lp-frame {
  @extend .lp-icon;
  background-image: url('../../icons/frame.svg') !important;
}

.lp-frame-active {
  @extend .lp-icon;
  background-image: url('../../icons/frame-active.svg') !important;
}

.lp-time-keeping {
  @extend .lp-icon;
  background-image: url('../../icons/time-keeping.svg') !important;
}

.lp-salary {
  @extend .lp-icon;
  background-image: url('../../icons/salary.svg') !important;
}

.lp-upload-file {
  @extend .lp-icon;
  background-image: url('../../icons/upload-file.svg') !important;
  height: 16px;
  width: 12px;
}

.lp-return-arrow {
  @extend .lp-icon;
  background-image: url('../../icons/return-arrow.svg') !important;
}

.lp-error-warning {
  @extend .lp-icon;
  background-image: url('../../icons/error.svg') !important;
}

.lp-allowance-new {
  @extend .lp-icon;
  background-image: url('../../icons/allowance-new.svg') !important;
  width: 30px;
  height: 30px;
}

.lp-course {
  @extend .lp-icon;
  background-image: url('../../icons/course.svg') !important;
}

.lp-view-private {
  @extend .lp-icon;
  background-image: url('../../icons/view-private.svg') !important;
}

.lp-course-private-circle {
  @extend .lp-icon;
  background-image: url('../../icons/private-circle.svg') !important;
}

.lp-lock {
  @extend .lp-icon;
  background-image: url('../../icons/lock.svg') !important;
}

.lp-done-green {
  @extend .lp-icon;
  background-image: url('../../icons/done-green.svg') !important;
}

.lp-green-check {
  @extend .lp-icon;
  background-image: url('../../icons/course-check.svg') !important;
}

.lp-add-arrow-right {
  @extend .lp-icon;
  width: 30px;
  height: 30px;
  background-image: url('../../icons/add-document-arrow-right.svg') !important;
}

.lp-white-cancel {
  @extend .lp-icon;
  background-image: url('../../icons/white-cancel.svg') !important;
}

.lp-rate-import {
  @extend .lp-icon;
  width: 40px;
  height: 40px;
  background-image: url('../../icons/rate-import.svg') !important;
}
