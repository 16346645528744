.p-multiselect {
  &:not(.p-disabled) {
    &.p-focus {
      box-shadow: inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main;
      border-color: $info-main;
    }
    &:hover {
      border-color: $info-main;
    }
  }
  width: 100% !important;
  &-item {
    margin: 0;
  }
  &.p-multiselect-chip {
    .p-multiselect-token {
      color: $primary-alternative-dark;
      background: $primary-outlined-hover-background;
    }
  }
}
.p-inputtext-sm {
  .p-multiselect-label {
    font-size: 0.875rem;
    padding: 0.875rem 0.875rem;
  }
}

.p-multiselect-panel {
  .p-multiselect-items {
    .p-multiselect-item {
      &.p-highlight {
        color: $primary-alternative-dark;
        background: $primary-outlined-hover-background;
      }
    }
  }
}
