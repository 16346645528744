.p-inputtext {
  font-family: inherit;
  width: 100%;
  padding: 13px 13px;
  color: $primary-alternative-dark;
  border-color: $neutral-3;
  text-overflow: ellipsis;

  &:enabled {
    &:focus {
      border-color: $info-main;
      box-shadow: inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main;
    }

    &:hover {
      border-color: $info-main;
    }
  }
}

.p-float-label {
  & > label {
    color: $text-primary;
  }

  .p-inputwrapper-focus {
    & ~ label {
      color: $info-main;
    }
  }

  input {
    &::placeholder {
      opacity: 0;
    }

    &:focus {
      &::placeholder {
        opacity: 1;
      }

      & ~ label {
        color: $info-main;
      }
    }
  }

  .custom-input-table .p-inputtext{
    padding: 6px;
    font-size: 12px;
  }
}
