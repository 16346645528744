.expanded-node {
  border: 0.5px solid $primary-main !important;
}

.p-organizationchart {

  .p-organizationchart-line-left {
    border-right: 2px solid $primary-main;
    border-color: $primary-main;
  }

  //
  .p-organizationchart-line-top {
    border-top: 2px solid $primary-main;
    border-color: $primary-main;
  }

  //
  //.p-organizationchart-line-right {
  //  border-right: 5px solid $primary-main;
  //}

  .p-organizationchart-line-down {
    background: $primary-main;
    width: 2px;
    height: 24px;
  }

  .p-organizationchart-node-content {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    //width: 290px;

    .p-node-toggler {
      border: .5px solid $primary-main;
      display: flex;
      align-items: center;
      justify-content: center;

      .p-node-toggler-icon {
        top: 0;
        font-size: 12px;
      }
    }
  }
}
