* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  color: $primary-alternative-dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #eceff4;

  .ajax-loader {
    font-size: 32px;
  }

  &.hidden-overflow {
    overflow: hidden;
  }
}

.p-component {
  font-family: inherit;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

//THONG TIN CHI TIET STYLE
.section-header { 
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    font-weight: 500;
    font-size: 1.43rem;
    text-transform: uppercase;
    color: $primary-alternative-dark;

  }
}

.section-body {
  padding: 0.5rem;
}

.section-sub-header {
  font-weight: 400;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  padding-left: 0.5rem;
  margin: 14px 0 12px 0;
  color: $primary-alternative-dark;

  h5 {
    font-weight: 400;
  }
}

.space-line {
  margin-left: 16px;
  margin-right: 16px;
  flex: 1;
  border: 0.2px solid $action-disabled;
}

.card {
  overflow-x: hidden;
  overflow-y: auto;
  //height: 100%;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #ffffff;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(58, 53, 65, 0.1);

  &:last-child {
    margin-bottom: 0;
  }

  .card-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .card-subtitle {
    color: #9199a9;
    font-weight: 600;
    margin: -1rem 0 1rem 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #fff8e1;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90a4ae;
}

hr {
  border-top: solid rgba(145, 153, 169, 0.3);
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}

p:last-child {
  margin-bottom: 0;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: 40px;
  width: 40px;
  transform: translate(-50%, -50%);
}

.layout-wrapper-small-menu {
  .main-section {
    margin-left: $left-menu-minimized-width !important;

    .main-app {
      width: calc(100vw - ($left-menu-minimized-width + 40px)) !important;
    }
  }

  .topbar {
    width: calc(100% - $left-menu-minimized-width) !important;
  }

}

.layout-wrapper-big-menu {
  .main-section {
    margin-left: $left-menu-width !important;

    .main-app {
      width: calc(100vw - ($left-menu-width + 40px)) !important;
    }
  }

  .topbar {
    width: calc(100% - $left-menu-width) !important;
  }
}

.main-section {
  background-color: $background;

  .app-section {
    height: 100%;
    position: fixed;
    top: $top-bar-height;
    padding: 0 16px;

    .main-app {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      //padding-bottom: 22px;
      height: calc(100vh - ($top-bar-height + 60px));
      width: calc(100vw - ($left-menu-minimized-width + 40px));
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;

    }
  }
}

.birthday-popup {
  width: 400px;
  max-height: 92% !important;
  height: 90%;
  overflow: auto;
  top: 55px !important;
}

.list-search {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-section {
    display: flex;
    align-items: center;
  }

  .right-section {
    display: flex;
    align-items: center;
  }
}

