.p-calendar {
  width: 100%;

  .p-button {
    box-shadow: none;
  }
}

.p-calendar-w-btn {
  &:not(.p-calendar-disabled) {
    &.p-focus {
      border-color: $info-main;
      box-shadow: inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main;
    }

    &:hover {
      border-color: $info-main;
    }
  }
  .p-button-icon-only {
    width: 38px !important;
    height: auto;
  }
}

.p-datepicker {
  .p-monthpicker {
    .p-monthpicker-month {
      &.p-highlight {
        color: $primary-main;
        background: $primary-outlined-hover-background
      }
    }
  }

  .p-yearpicker {
    .p-yearpicker-year {
      &.p-highlight {
        color: $primary-main;
        background: $primary-outlined-hover-background
      }
    }
  }

  .p-datepicker-header {
    .p-datepicker-title {
      .p-datepicker-month, .p-datepicker-year {
        &:enabled {
          &:hover {
            color: $primary-main;
          }
        }
      }
    }
  }

  table {
    td {
      & > span {
        &.p-highlight {
          color: $primary-main;
          background: $primary-outlined-hover-background;
        }
      }

      &.p-datepicker-today {
        & > span {
          &.p-highlight {
            color: $primary-main;
            background: $primary-outlined-hover-background;
          }
        }
      }
    }
  }
}
