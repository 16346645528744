/* You can add global styles to this file, and also import other style files */
@import 'variable';
@import 'layout';
@import 'theme/index';
@import 'custom';
@import "animation";
@import "menu";
@import 'reset';

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./assets/fonts/Inter/Inter-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./assets/fonts/Inter/Inter-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./assets/fonts/Inter/Inter-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./assets/fonts/Inter/Inter-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./assets/fonts/Inter/Inter-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}


/* Track */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #878c90;
}

/* Handle on hover */
