.p-button {
  //box-shadow: 0px 4px 8px -4px #3A35416B;
  background: $primary-main;
  padding: 0.75rem 1.65rem;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;

  &.p-button-outlined {
    color: $primary-main;

    &:enabled {
      &:hover {
        background: rgba($primary-main, 0.04);
        color: $primary-main;
      }

      &:active {
        background: rgba($primary-main, 0.04);
        color: $primary-main;
      }

      &:focus {
        background: rgba($primary-main, 0.04);
      }
    }
  }

  &.p-button-icon-only {
    width: 38px;
    height: 38px;
  }

  &:enabled {


    &:hover {
      background-color: rgba(29, 142, 208, 0.8);
    }

    &:focus {
      background-color: $primary-main;
    }
  }

  &.p-button-danger {
    &.p-button-outlined {
      color: $error-main;

      &:enabled {
        &:hover {
          color: $error-main;
        }
      }
    }
  }
}

.p-button-label {
  text-transform: uppercase;
}
