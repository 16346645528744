.p-tag {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  font-weight: 400;
  padding: 0.25rem 10px;

  &.success {
    //green
    color: $success-main;
    background: $success-outlined-hover-background;
    border: 1px solid $success-main;
    width: 105.7px !important;
  }

  &.danger {
    //red
    color: $error-main;
    background: $error-outlined-hover-background;
    border: 1px solid $error-main;
    width: 105.7px !important;
  }

  &.warning {
    // yellow
    color: $warning-main;
    background: $warning-outlined-hover-background;
    border: 1px solid $warning-main;
    width: 105.7px !important;
  }

  &.info {
    // green dark
    color: $success-alternative-dark;
    background: $success-outlined-hover-background;
    border: 1px solid $success-alternative-dark;
  }

  &.in-prim {
    //-blue dark
    color: $primary-alternative-dark;
    background: $primary-outlined-hover-background;
    border: 1px solid $primary-alternative-dark;
    width: 105.7px !important;
  }

  &.primary {
    // blue
    color: $primary-main;
    background: $custom-bg-primary;
    border: 1px solid $primary-main;
    width: 105.7px !important;
  }

  &.secondary {
    // grey-xám
    color: $secondary-alternative-light;
    background: $secondary-outlined-hover-background;
    border: 1px solid $secondary-alternative-light;
    width: 105.7px !important;
  }

  &.secondarys {
    // grey-xám
    color: $secondary-alternative-lights;
    background: $secondary-outlined-hover-background;
    border: 1px solid $secondary-alternative-light;
    width: 105.7px !important;
  }

  &.purple {
    //purple-tím
    color: $purple-default;
    background: $purple-outlined-hover-background;
    border: 1px solid $purple-default;
    width: 105.7px !important;
  }

  &.new {
    //-blue dark
    color: $primary-alternative-dark;
    background: $greenness-outlined-hover-background;
    border: 1px solid $greenness-default;
    width: 105.7px !important;
  }

  &.pending {
    //-blue dark background card
    color: $primary-alternative-dark;
    background: $white;
    border: 1px solid $primary-alternative-dark;
    width: 105.7px !important;
  }

  &.sky {
    color: $white;
    background: $primary-main;
    font-size: 13px;
  }
}
