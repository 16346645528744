.p-dialog-body {
  margin: 0 !important;
  flex: 1 1 auto;
  overflow: auto;
  padding: 0.5rem 0.5rem;
}

.p-dialog {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden; 
  width: 400px;

  .p-dialog-header {
    padding: 7px;
    //border-bottom: 1px solid $primary-main;

    .p-dialog-title {
      font-size: 20px;
      font-weight: 500;
      color: $primary-alternative-dark;
    }
  }

  .p-dialog-content {
    padding: 7px;
    display: flex;
    flex-direction: column;
    color: $primary-alternative-dark !important; 

    .p-confirm-dialog-message {
      padding: 7px;
      color: $primary-alternative-dark; 
      text-align: center;
    }
  }

  .p-dialog-footer {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
