.p-treeselect {
  .p-treeselect-label {
    padding: 13px 13px;
    color: $primary-alternative-dark;
  }

  &:not(.p-disabled) {
    &:hover {
      border-color: $info-main;
    }

    &.p-focus {
      box-shadow: inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main, inset 0 0 0 1px $info-main;
    }
  }
}

.p-tree {
  .p-tree-container {
    .p-treenode {
      .p-treenode-content {
        &.p-highlight {
          color: $info-main;
          background-color: $primary-outlined-hover-background;
          .p-tree-toggler {
            color: $info-main;
            &:hover {
              color: $info-main;
            }
          }
        }
      }
    }
  }
}
